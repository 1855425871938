'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.dispatch.factory:DispatchUnitTypesManager

 # @description

###
angular
  .module 'mundoAdmin.dispatch'
  .factory 'DispatchUnitTypesManager', [
    'MundoDefaultManager'
    'TenantManager'
    '$rootScope'
    '$mdDialog'
    'RestUtils'
    (
      MundoDefaultManager
      TenantManager
      $rootScope
      $mdDialog
      RestUtils
    )->
      DispatchUnitTypesManagerBase = new MundoDefaultManager()

      DispatchUnitTypesManagerBase.setUrl('lpa/dispatch/units/types')
      DispatchUnitTypesManagerBase.setAutoTenant()
      DispatchUnitTypesManagerBase.setNewObject(['label', 'weight'])
      DispatchUnitTypesManagerBase.setUpdateObject(['label', 'weight'])
      DispatchUnitTypesManagerBase.setSearchFields(['label', 'tenant.label'])
      DispatchUnitTypesManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:DispatchUnitType entities'

      DispatchUnitTypesManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'weight'
          name: 'weight'
          type: 'input'
          templateOptions:
            label: 'Gewicht'
            placeholder: ''
            required: false
        ]

      DispatchUnitTypesManagerBase.getEditForm = (data) ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'weight'
          name: 'weight'
          type: 'input'
          defaultValue: data.weight
          templateOptions:
            label: 'Gewicht'
            placeholder: ''
            required: false
        ]

      DispatchUnitTypesManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'weight'
          title: 'datatable.label.weight'
          sort: 'weight'
        ]

      DispatchUnitTypesManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/dispatch/views/dispatch-unit-type-detail.tpl.html'

      DispatchUnitTypesManagerBase.setObject('dispatchUnitTypeCode', ['code'])

      DispatchUnitTypesManagerBase.getCodes = (id) ->
        RestUtils.getFullList @transport.one(@conf.url, id).all('codes')

      DispatchUnitTypesManagerBase.addCode = (id, data) ->
        code = _.pick(data, @getObject('dispatchUnitTypeCode'))
        code.tenant = $rootScope.user.activeUserContext.tenant.id

        @transport.one(@conf.url, id).all('codes').post(code)

      DispatchUnitTypesManagerBase.removeCode = (dispatchUnitTypeId, codeId) ->
        @transport.one(@conf.url, dispatchUnitTypeId).one('codes', codeId).remove()

      DispatchUnitTypesManagerBase.getCodeForm = ->
        [
          key: 'code'
          name: 'code'
          type: 'input'
          templateOptions:
            label: 'Code'
            placeholder: 'code'
            required: true
        ]

      assignCode =
        [
          icon: 'code'
          text: 'datatable.tooltips.editCodes'
          permission: DispatchUnitTypesManagerBase.editPermission
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/dispatch/views/dispatch-unit-type-codes.modal.tpl.html'
              controller: 'DispatchUnitTypeCodesCtrl'
              controllerAs: 'ctrl'
              locals:
                id: id
        ]

      DispatchUnitTypesManagerBase.addExtraAction(assignCode)

      # DispatchUnitTypesManagerBase.addDetailEditAction('editCode', [
      #   title: 'Edit Code'
      #   entityManager: DispatchUnitTypeManagerBase
      # ])

      DispatchUnitTypesManagerBase
  ]
